/*
 * Copyright (C) 2019-2099 Deutsche Post DHL Group. All rights reserved.
 * This code is licensed and the sole property of Deutsche Post DHL Group.
 */

import { useEffect } from "react";
import { LocalizationPack, logger } from "@gkuis/gkp-base-widgets";
import { CustomerConfigStore } from "../types/CustomerConfigStore";

export const useLoadCustomerConfiguration = (ekp: string,
                                             store: CustomerConfigStore,
                                             localizationPack: LocalizationPack,
                                             componentLogName: string) => {

  useEffect(() => {
    if (localizationPack.hasData) {
      store.clearTemporaryMessages();
      store.loadConfiguration(ekp);
      logger.log(componentLogName + " - loaded configuration");
    }
  }, [ekp, localizationPack.hasData, componentLogName, store]);
};